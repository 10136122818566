import * as Sentry from "@sentry/nuxt";

const { public: { sentry } } = useRuntimeConfig()

Sentry.init({
  dsn: sentry.dsn,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.01
});
